var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            {
              staticClass: "text-md-left text-center",
              attrs: { cols: "6", lg: "4" }
            },
            [
              _c("span", { staticClass: "h4 text--white" }, [
                _vm._v("Companies")
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "text-right mt-2 mt-lg-0",
              attrs: { cols: "6", lg: "4", "offset-lg": "4" }
            },
            [
              _c("AddEditCompany", {
                on: {
                  refresh: function($event) {
                    return _vm.init()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-card",
            {
              staticClass: "card-border text--black q-table-container",
              staticStyle: { "overflow-x": "auto" }
            },
            [
              _c(
                "div",
                { staticClass: "float-left mb-2" },
                [
                  _vm._v(" Search "),
                  _c("b-form-input", {
                    staticClass: "d-inline w-auto",
                    attrs: { debounce: "500" },
                    model: {
                      value: _vm.text,
                      callback: function($$v) {
                        _vm.text = $$v
                      },
                      expression: "text"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-left mb-2 ml-3" },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: { value: null, "unchecked-value": 1 },
                      model: {
                        value: _vm.filters.active,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "active", $$v)
                        },
                        expression: "filters.active"
                      }
                    },
                    [_vm._v("Show in-active")]
                  )
                ],
                1
              ),
              _vm.totalItems > _vm.filters.max
                ? _c("b-pagination", {
                    staticClass: "float-right",
                    attrs: {
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.filters.max,
                      "aria-controls": "documentsTable"
                    },
                    on: { change: _vm.changePage },
                    model: {
                      value: _vm.filters.page,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "page", $$v)
                      },
                      expression: "filters.page"
                    }
                  })
                : _vm._e(),
              _c("b-table", {
                staticStyle: { "min-width": "1080px" },
                attrs: {
                  items: _vm.companies,
                  "no-local-sorting": "",
                  fields: _vm.fields,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.desc,
                  striped: ""
                },
                on: {
                  "sort-changed": _vm.sortingChanged,
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.desc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.desc = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "link u",
                            attrs: { to: "/admin/company/" + data.item.id }
                          },
                          [_vm._v(" " + _vm._s(data.item.name) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(organization)",
                    fn: function(data) {
                      return [
                        _c(
                          "AddEditOrganization",
                          {
                            attrs: {
                              name: data.item.organization,
                              id: +data.item.organization_id,
                              edit: true
                            },
                            on: {
                              refresh: function($event) {
                                return _vm.init()
                              }
                            }
                          },
                          [
                            [
                              _c("span", { staticClass: "link u" }, [
                                _vm._v(
                                  " " + _vm._s(data.item.organization) + " "
                                )
                              ])
                            ]
                          ],
                          2
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(data) {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "AddEditCompany",
                              {
                                attrs: { compId: +data.item.id, edit: "" },
                                on: {
                                  refresh: function($event) {
                                    return _vm.init()
                                  }
                                }
                              },
                              [
                                [
                                  _c(
                                    "b-button",
                                    { attrs: { variant: "warning" } },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "pencil" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ],
                              2
                            ),
                            _c(
                              "AddUser",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  orgId: +data.item.organization_id,
                                  companyId: +data.item.id
                                },
                                on: {
                                  refresh: function($event) {
                                    return _vm.init()
                                  }
                                }
                              },
                              [
                                [
                                  _c(
                                    "b-button",
                                    { attrs: { variant: "success" } },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "person-plus-fill" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-2 d-inline" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push(
                                          "/documents/" + data.item.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "file-text" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm.totalItems > _vm.filters.max
                ? _c("b-pagination", {
                    staticClass: "float-right",
                    attrs: {
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.filters.max,
                      "aria-controls": "documentsTable"
                    },
                    on: { change: _vm.changePage },
                    model: {
                      value: _vm.filters.page,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "page", $$v)
                      },
                      expression: "filters.page"
                    }
                  })
                : _vm._e(),
              _vm.totalItems > 10
                ? _c("div", { staticClass: "pl-1 text-left" }, [
                    _vm._v(" Per page: "),
                    _c(
                      "span",
                      {
                        staticClass: "ml-2 link",
                        class: _vm.filters.max == 10 ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.changeMax(10)
                          }
                        }
                      },
                      [_vm._v("10")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "ml-2 link",
                        class: _vm.filters.max == 25 ? "active" : "",
                        attrs: { href: "javasript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.changeMax(25)
                          }
                        }
                      },
                      [_vm._v("25")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "ml-2 link",
                        class: _vm.filters.max == 50 ? "active" : "",
                        attrs: { href: "javasript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.changeMax(50)
                          }
                        }
                      },
                      [_vm._v("50")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "ml-2 link",
                        class: _vm.filters.max == 100 ? "active" : "",
                        attrs: { href: "javasript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.changeMax(100)
                          }
                        }
                      },
                      [_vm._v("100")]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }