var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "div",
        {
          staticClass: "ma-0 pa-0 d-inline",
          on: {
            click: function($event) {
              _vm.showModal = true
            }
          }
        },
        [
          _vm._t("default", function() {
            return [
              _c(
                "b-button",
                { attrs: { variant: "success", block: "" } },
                [
                  _c("b-icon", {
                    attrs: { icon: _vm.edit ? "pencil" : "plus" }
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.edit ? "Edit" : "Add") + " Organization "
                  )
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "updateorganization",
            title: (_vm.edit ? "Edit" : "Add") + " Organization",
            "ok-variant": "primary",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          on: {
            hidden: function($event) {
              return _vm.reset()
            },
            shown: function($event) {
              return _vm.reset()
            },
            ok: _vm.createOrg
          },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "check" } }),
                  _vm._v(" Save ")
                ]
              },
              proxy: true
            },
            {
              key: "modal-cancel",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "x" } }),
                  _vm._v(" Cancel ")
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Name", "label-align": "left" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.organization.name,
                  callback: function($$v) {
                    _vm.$set(_vm.organization, "name", $$v)
                  },
                  expression: "organization.name"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }